import {convertToUTCWithOffsetFormattedWithSeconds, getCurrentUTCDateTimeWithSeconds} from '../../helpers/timeHelpers';
import loadModels from './objectDetectionModels'; // Adjust path as necessary


let modelsLoaded = false;
let loadedModels;

// Ensure models are loaded before using detectChanges
const ensureModelsLoaded = async () => {
    if (!modelsLoaded) {
        try {
            loadedModels = await loadModels();
            if(loadedModels != '') {
                modelsLoaded = true;
            }
        } catch (err) {
            console.log(err);
        }
    }
    return loadedModels;
};

export const  tryInitializeWebcam = (webcamDivRef, retryCount) => {
    const webcamElement = webcamDivRef.current;
    if (!webcamElement) {
        retryCount++;
        if (retryCount <= 5) { // Retry 5 times (adjust as needed)
            setTimeout(() => tryInitializeWebcam(webcamDivRef, retryCount), 1000);
        }
        return;
    }
    console.log('Initialized');
    // Initialize draggable functionality here
    let isDragging = false;
    let startX, startY;

    const onMouseDown = (e) => {
        isDragging = true;
        startX = e.clientX - webcamElement.offsetLeft;
        startY = e.clientY - webcamElement.offsetTop;
        document.addEventListener('mousemove', onMouseMove);
        document.addEventListener('mouseup', onMouseUp);
    };

    const onMouseMove = (e) => {
        if (!isDragging) return;
        const newLeft = e.clientX - startX;
        const newTop = e.clientY - startY;
        webcamElement.style.left = `${newLeft}px`;
        webcamElement.style.top = `${newTop}px`;
    };

    const onMouseUp = () => {
        isDragging = false;
        document.removeEventListener('mousemove', onMouseMove);
        document.removeEventListener('mouseup', onMouseUp);
    };

    webcamElement.addEventListener('mousedown', onMouseDown);

    return () => {
        webcamElement.removeEventListener('mousedown', onMouseDown);
        document.removeEventListener('mousemove', onMouseMove);
        document.removeEventListener('mouseup', onMouseUp);
    };
};


let previousLeftEye = [];
let previousRightEye = [];

const smoothLandmarks = (newLandmarks, previousLandmarks, alpha = 0.01) => {
    if (!previousLandmarks.length) {
        return newLandmarks;
    }
    return newLandmarks.map((landmark, index) => {
        return {
            x: alpha * landmark.x + (1 - alpha) * previousLandmarks[index].x,
            y: alpha * landmark.y + (1 - alpha) * previousLandmarks[index].y,
        };
    });
};

const getEyeLandmarks = (landmarks) => {
    const leftEyeIndices = [33, 133, 145, 153, 154]; 
    const rightEyeIndices = [362, 263, 374, 380, 381];
    let leftEye = leftEyeIndices.map(index => landmarks[index]);
    let rightEye = rightEyeIndices.map(index => landmarks[index]);
    // Smooth landmarks with previous frame data
    //leftEye = smoothLandmarks(leftEye, previousLeftEye);
    //rightEye = smoothLandmarks(rightEye, previousRightEye);
    // Save the landmarks for the next frame
    previousLeftEye = leftEye;
    previousRightEye = rightEye;

    return { leftEye, rightEye };
};

const adjustBrightnessAndContrast = (imageTensor, tf, brightnessFactor = 1, contrastFactor = 1.0) => {
    return tf.tidy(() => {
        // Adjust brightness by adding the brightness factor to the image tensor
        let adjustedImage = imageTensor.add(tf.scalar(brightnessFactor));
        // Adjust contrast by scaling relative to the mean pixel value
        const mean = adjustedImage.mean();
        adjustedImage = adjustedImage.sub(mean).mul(tf.scalar(contrastFactor)).add(mean);

        return adjustedImage;
    });
};

// Function to detect eyes using Mediapipe
const detectEyes = async (image, faceMeshModel, tf) => {
    let landmarks = '';
    try {
        faceMeshModel.onResults((results) => {
            if (results.multiFaceLandmarks && results.multiFaceLandmarks.length > 0) {
                landmarks = results.multiFaceLandmarks[0];  // Get the landmarks of the first face
            }
        });
        await faceMeshModel.send({image});
        if (!landmarks) {
            console.log('No face landmarks detected');
            return '';
        }
    } catch (err) {
        console.log(err);
        return '';
    }
    // Extract only the eye landmarks
    const eyeLandmarks = getEyeLandmarks(landmarks);
    return eyeLandmarks; 
};

// Function to check if the user is looking away based on eye landmarks
const checkIfLookingAway = (eyeLandmarks) => {
    const { leftEye, rightEye } = eyeLandmarks;

    // Calculate the gaze directions for both eyes
    const leftGaze = calculateGazeDirection(leftEye);
    const rightGaze = calculateGazeDirection(rightEye);

    // Define thresholds for horizontal and vertical gaze. These can be fine-tuned based on testing
    const horizontalThreshold = 0.52; 
    const verticalThreshold = 5;   // Fine-tuned vertical threshold
    const depthThreshold = 0.01;      // Slightly higher threshold for depth factor

    console.log(Math.abs(leftGaze.horizontalGaze)+' > '+horizontalThreshold, Math.abs(leftGaze.horizontalGaze) > horizontalThreshold);
    console.log(Math.abs(leftGaze.verticalGaze)+' > '+verticalThreshold, Math.abs(leftGaze.verticalGaze) > verticalThreshold);
    console.log(leftGaze.depthFactor+' > '+depthThreshold, leftGaze.depthFactor > depthThreshold);

    // Check if the user is looking away horizontally or vertically or moving too far forward/backward
    const isLookingAway = (
        Math.abs(leftGaze.horizontalGaze) > horizontalThreshold ||
        Math.abs(leftGaze.verticalGaze) > verticalThreshold ||
        leftGaze.depthFactor > depthThreshold
    );

    return isLookingAway;
};

const calculateGazeDirection = (eye) => {
    const [leftCorner, rightCorner, pupil] = eye;
    // Calculate the horizontal gaze direction using the x coordinates
    const horizontalGaze = (pupil.x - leftCorner.x) / (rightCorner.x - leftCorner.x);
    // Calculate the vertical gaze direction using the y coordinates
    const verticalGaze = (pupil.y - leftCorner.y) / (rightCorner.y - leftCorner.y);
    // Optionally, adjust based on depth (z-axis) if necessary
    const depthFactor = Math.abs(pupil.z - (leftCorner.z + rightCorner.z) / 2);
    // Return a combined gaze direction, or return them separately
    return { horizontalGaze, verticalGaze, depthFactor };
};

export const detectChanges = async (previousPredictions, currentPredictions) => {
    let sendInfoToServer = false;
    const filterPredictions = (predictions, className) => predictions.filter(prediction => prediction.class === className);

    // Filter predictions by person, face, hand, and cell phone classes
    const personsInCurrentFrame = filterPredictions(currentPredictions, 'person');
    const personsInPreviousFrame = filterPredictions(previousPredictions, 'person');
    const phonesInPreviousFrame = filterPredictions(previousPredictions, 'cell phone');
    const phonesInCurrentFrame = filterPredictions(currentPredictions, 'cell phone');

    const morePersonsDetected = personsInCurrentFrame.length > 1 ;
    if(morePersonsDetected && personsInPreviousFrame.length != personsInCurrentFrame.length) {
        sendInfoToServer = true;
    }
    const noPersonsDetected = personsInCurrentFrame.length < 1 ;
    if(noPersonsDetected)console.log('personsInPreviousFrame.length != personsInCurrentFrame.length-', personsInPreviousFrame.length, personsInCurrentFrame.length);
    if(noPersonsDetected && personsInPreviousFrame.length != personsInCurrentFrame.length) {
        sendInfoToServer = true;
    }

    // Check if a phone is detected near a person's bounding box
    const phoneDetectedNearPerson = phonesInCurrentFrame.length > 0 ;
    if(phoneDetectedNearPerson && phonesInCurrentFrame.length != phonesInPreviousFrame.length) {
        sendInfoToServer = true;
    }

    return { phoneDetectedNearPerson, morePersonsDetected, noPersonsDetected, sendInfoToServer };
};


export const captureAndStream = async (webcamRef, user, exam_id, socket, isExamSubmitted, isFirstFrameRef, previousPredictions, setDetectedInfo, detectedInfo) => {
    if (webcamRef.current && !isExamSubmitted) {
        const imageSrc = webcamRef.current.getScreenshot();
        const { tf, objectDetectionModel, faceMeshModel } = await ensureModelsLoaded();

        if (imageSrc && objectDetectionModel) {
            const img = new Image();
            img.onload = async () => {
                let predictions = await objectDetectionModel.detect(img);
                let eyeLandmarks = await detectEyes(img, faceMeshModel, tf);

                if (isFirstFrameRef.current) {
                    previousPredictions.current = predictions;
                    isFirstFrameRef.current = false;
                    socket.emit('video_stream', { user_id: user._id, exam_id, image: imageSrc, reason: 'First frame.' });
                    return;
                }

                // Detect changes between previous and current predictions
                const { phoneDetectedNearPerson, morePersonsDetected, noPersonsDetected, sendInfoToServer } = await detectChanges(previousPredictions.current, predictions);
                let reason = 'AI Video Proctoring.', isLookingAway = false;
                if (phoneDetectedNearPerson) {
                    reason = 'Use of mobile is detected.';
                    setDetectedInfo(reason);
                } else if (morePersonsDetected) {
                    reason = 'Detected more than one person.';
                    setDetectedInfo(reason);
                } else if (noPersonsDetected) {
                    reason = 'No person detected in frame. Test taker might have moved away.';
                    setDetectedInfo(reason);
                } else if (eyeLandmarks) {
                    isLookingAway = checkIfLookingAway(eyeLandmarks);
                    if (isLookingAway) {
                        reason = 'Test taker is looking away from the screen.';
                        setDetectedInfo(reason);
                    } else {
                        setDetectedInfo('Caution: AI is monitoring you..');
                    }
                } else {
                    await setDetectedInfo('Caution: AI is monitoring you..');
                }

                if(sendInfoToServer == true || isLookingAway == true) {
                    console.log('Sending image to server');
                    socket.emit('video_stream', { user_id: user._id, exam_id, image: imageSrc, reason: reason });
                } else {
                    console.log('No need to send image to server.');
                }
                previousPredictions.current = predictions; // Update previous predictions
            };
            img.src = imageSrc;
        } else {
            console.log('ObjectDetection model not initialized');
        }
    } else {
        console.warn('Webcam reference is not available.');
    }
};

export const setTimer = (current,endDate, setTimerValue) => {
    // let current = new Date();
    let EndDate = new Date(endDate);
    // EndDate.setMinutes(EndDate.getMinutes()-30);
    // EndDate.setHours(EndDate.getHours()-5);
    let totalMS = EndDate.getTime() - current.getTime();
    let totalSeconds = Math.floor(totalMS / 1000);
    let totalHours = Math.floor(totalSeconds / 3600)
    totalSeconds -= totalHours * 3600;
    let totalMinutes = Math.floor(totalSeconds / 60);
    totalSeconds -= totalMinutes * 60;
    setTimerValue([totalHours, totalMinutes, totalSeconds]);
}

export const runTimer = (isExamSubmitted, timerValue, setTimerValue, submitAnswers, setAdditionalInfo, setTimeIntervals) => {
    let runningTimer = setInterval(() => {
        if (isExamSubmitted) {
            clearInterval(runningTimer);
            return;
        }
        if (timerValue[0] <= 0 && timerValue[1] <= 0 && timerValue[2] <= 0) {
            clearInterval(runningTimer);
            // console.log(timeSpentPerQuestions," time spent in runtimer");
            
            submitAnswers();
            setAdditionalInfo('Your exam has been automatically submitted because the time is over.')
            return;
        } else {
            if (timerValue[2] > 0) {
                let temporaryTimerValue = timerValue;
                temporaryTimerValue[2] -= 1;
                setTimerValue([...temporaryTimerValue]);
            } else {
                if (timerValue[1] > 0) {
                    let temporaryTimerValue = timerValue;
                    temporaryTimerValue[1] -= 1;
                    temporaryTimerValue[2] = 59;
                    setTimerValue([...temporaryTimerValue]);
                } else {
                    if (timerValue[0] > 0) {
                        let temporaryTimerValue = timerValue;
                        temporaryTimerValue[0] -= 1;
                        temporaryTimerValue[1] = 59;
                        temporaryTimerValue[2] = 59;
                        setTimerValue([...temporaryTimerValue]);
                    }
                }
            }
        }
    }, 1000);
    setTimeIntervals(prev => [...prev, runningTimer]);
}

export const calculateDuration = async(Exam, setRemainingDays, setRemainingHours, setRemainingMinutes, setRemainingSeconds, setExamWillStartTime) => {
    var startDate = await convertToUTCWithOffsetFormattedWithSeconds(Exam.startDate, Exam.timeZoneOffset.offset);
    startDate = new Date(startDate)
    var endDate = await getCurrentUTCDateTimeWithSeconds()
    endDate = new Date(endDate);
    
    setInterval(function () {
        console.log(startDate, endDate,"---utc")
        const diffMs = startDate.getTime() - endDate.getTime();
        console.log("diff is utc", diffMs)
        const diffDays = Math.floor(diffMs / 86400000); // Days
        const diffHrs = Math.floor((diffMs % 86400000) / 3600000); // Hours
        const diffMins = Math.floor((diffMs % 3600000) / 60000); // Minutes
        const seconds = Math.floor((diffMs % (1000 * 60)) / 1000);
        if (diffMs > 0) {
            setRemainingDays(diffDays);
            setRemainingHours(diffHrs);
            setRemainingMinutes(diffMins);
            setRemainingSeconds(seconds);
            setExamWillStartTime(`${diffDays > 0 ? `${diffDays} <span>Day(s)</span>, ` : ''}${diffHrs} Hour(s) : ${diffMins} Minute(s) : ${seconds} Seconds`);
            endDate.setTime(endDate.getTime() + 1000);
        } else {
            window.location.reload();
        }
    }, 1000);
    return '';
};

export const checkExamAvailability = async(Exam, setTimerValue, setExamAvailability, setRemainingDays, setRemainingHours, setRemainingMinutes, setRemainingSeconds, setExamWillStartTime) => {
    if (Object.keys(Exam).length > 0) {
        if (Object.keys(Exam).includes('startDate')) {
           
            const {offset} = Exam.timeZoneOffset;
            var current = await getCurrentTimeInTimezone(offset);
            current = new Date(current);
            let start = new Date(Exam.startDate);
            // start.setMinutes(start.getMinutes());
            // start.setHours(start.getHours());
            let end = new Date(Exam.endDate);
            // end.setMinutes(end.getMinutes());
            // end.setHours(end.getHours());
            const examAvailability = current >= start && current <= end;
            // alert(`${current},${start},${end}, availability" ${examAvailability}`)
            if (examAvailability) {
                setTimer(current,end, setTimerValue);
            } else if (current < start) {
                calculateDuration(Exam, setRemainingDays, setRemainingHours, setRemainingMinutes, setRemainingSeconds, setExamWillStartTime);
            }
            setExamAvailability(examAvailability);
        } else {
            setExamAvailability(true);
        }
    } else {
        // clearInterval(timer);
    }
}

export const convertToUtc = async(dateString, offsetString) => {
    // Parse the date string into a Date object
    const localDate = new Date(dateString);

    // Extract the sign, hours, and minutes from the offset string correctly
    const sign = offsetString[0]; // "+" or "-"
    const [hours, minutes] = offsetString.slice(1).split(':'); // Extract hours and minutes

    // Calculate the total offset in minutes
    const totalOffsetMinutes = (parseInt(hours) * 60) + parseInt(minutes);

    // Convert the total offset into milliseconds and adjust based on sign
    const offsetInMillis = (sign === "+" ? -1 : 1) * totalOffsetMinutes * 60 * 1000;

    // Calculate UTC time by adding/subtracting the offset from the local time
    const utcTime = new Date(localDate.getTime() + offsetInMillis);

    return utcTime;
}

export async function getCurrentTimeInTimezone(offsetString) {
    // Extract the sign, hours, and minutes from the offset string
    const sign = offsetString[0] === '-' ? -1 : 1;
    const [hours, minutes] = offsetString.slice(1).split(':').map(Number);

    // Calculate the total offset in hours
    const offset = sign * (hours + minutes / 60);

    // Create a new Date object representing the current time in UTC
    const now = new Date();

    // Get the current time in UTC (milliseconds since January 1, 1970)
    const utcTime = now.getTime() + (now.getTimezoneOffset() * 60000);

    // Calculate the time in the desired time zone by adding the offset (converted to milliseconds)
    const timezoneTime = new Date(utcTime + (offset * 3600000));

    // Format the date to a readable string
    return timezoneTime
}

