import React, { useState, useEffect, useContext, useMemo, useCallback } from 'react';
import './examreports.css';
import Context from '../../user_details';
import axios from 'axios';
import Modal from 'react-modal';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, ArcElement } from 'chart.js';
import { Bar, Pie } from 'react-chartjs-2';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, ArcElement);

Modal.setAppElement('#root');

export default function ExamApp() {
  const [selectedExam, setSelectedExam] = useState(null);
  const [exams, setExams] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [examDetails, setExamDetails] = useState(null);
  const { user } = useContext(Context);

  const fetchExams = async (page = 1) => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/exam/list-attended-exams/${user._id}`, {
        params: { page, limit: 10 }
      });
      setExams(response.data.exams);
      setCurrentPage(response.data.currentPage);
      setTotalPages(response.data.totalPages);
    } catch (error) {
      console.error('Error fetching exams:', error);
    }
  };

  useEffect(() => {
    fetchExams(currentPage);
  }, [currentPage]);

  const openModal = useCallback(async (exam) => {
    try {
      setIsModalOpen(true);
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/exam/attended-exams-details/${user._id}/${exam.exam_id._id}`);
      setExamDetails(response.data);
    } catch (error) {
      console.error('Error fetching exam details:', error);
    }
  }, [user._id]);

  const closeModal = () => {
    setIsModalOpen(false);
    setExamDetails(null);
  };

  const scoreDistributionData = useMemo(() => {
    if (!examDetails) return {};
    const { correctAnswersCount, incorrectAnswersCount, skippedAnswersCount } = examDetails;
    return {
      labels: ['Correct', 'Incorrect', 'Skipped'],
      datasets: [
        {
          label: 'Answers Distribution',
          data: [correctAnswersCount, incorrectAnswersCount, skippedAnswersCount],
          backgroundColor: ['#4CAF50', '#F44336', '#FFC107'],
        },
      ],
    };
  }, [examDetails]);

  const questionComplexityData = useMemo(() => {
    if (!examDetails || !examDetails.questionDetails || !examDetails.questionDetails.length) return null;

    const complexityCount = examDetails.questionDetails.reduce((acc, detail) => {
      if (detail.complexity) {
        let label = 'Unknown';
        switch (detail.complexity) {
          case 1:
            label = 'Easy';
            break;
          case 2:
            label = 'Medium';
            break;
          case 3:
            label = 'Hard';
            break;
          default:
            break;
        }
        acc[label] = (acc[label] || 0) + 1;
      }
      return acc;
    }, {});

    const labels = Object.keys(complexityCount);
    const data = Object.values(complexityCount);

    return {
      labels: labels,
      datasets: [
        {
          label: 'Attempted',
          data: data,
          backgroundColor: ['#2196F3', '#FF9800', '#9C27B0'],
        },
      ],
    };
  }, [examDetails]);

  const sectionWiseMarksData = useMemo(() => {
    if (!examDetails || !examDetails.sectionDetails) return null;

    const labels = examDetails.sectionDetails.map(section => section.section_name);
    const totalCorrectAnswers = examDetails.sectionDetails.map(section => section.totalCorrectAnswers);
    const totalAssignedQuestions = examDetails.sectionDetails.map(section => section.totalAssignedQuestions);

    return {
      labels: labels,
      datasets: [
        {
          label: '# of Correct Answers',
          data: totalCorrectAnswers,
          backgroundColor: '#2196F3',
        },
        {
          label: '# of Assigned Questions',
          data: totalAssignedQuestions,
          backgroundColor: '#F44336',
        },
      ],
    };
  }, [examDetails]);

  const timeSpentData = useMemo(() => {
    if (!examDetails || !examDetails.questionDetails) return null;

    const totalSpentTime = examDetails.questionDetails.reduce((acc, detail) => acc + (detail.timeSpent || 0), 0);
    const avgTimePerQuestion = totalSpentTime / examDetails.questionDetails.length;

    return {
      labels: ['Total Time Spent', 'Average Time per Question'],
      datasets: [
        {
          label: 'Time (in seconds)',
          data: [totalSpentTime, avgTimePerQuestion],
          backgroundColor: ['#03A9F4', '#8BC34A'],
        },
      ],
    };
  }, [examDetails]);

  const learningOutcomesData = useMemo(() => {
    if (!examDetails || !examDetails.learningOutcomes) return null;

    const labels = examDetails.learningOutcomes.map(outcome => outcome.outcomeName);
    const scoredMarksData = examDetails.learningOutcomes.map(outcome => outcome.scoredMarks);
    const totalMarksData = examDetails.learningOutcomes.map(outcome => outcome.totalMarks);

    return {
      labels: labels,
      datasets: [
        {
          label: 'Scored Marks',
          data: scoredMarksData,
          backgroundColor: '#2196F3',
        },
        {
          label: 'Total Marks',
          data: totalMarksData,
          backgroundColor: '#F44336',
        },
      ],
    };
  }, [examDetails]);

  const subjectWisePerformanceData = useMemo(() => {
    return null;
    
    if (!examDetails || !examDetails.subjectDetails) return null;

    const labels = examDetails.subjectDetails.map(subject => subject.subjectName);
    const scoredMarksData = examDetails.subjectDetails.map(subject => subject.scoredMarks);
    const totalMarksData = examDetails.subjectDetails.map(subject => subject.totalMarks);

    return {
      labels: labels,
      datasets: [
        {
          label: 'Scored Marks',
          data: scoredMarksData,
          backgroundColor: '#2196F3',
        },
        {
          label: 'Total Marks',
          data: totalMarksData,
          backgroundColor: '#F44336',
        },
      ],
    };
  }, [examDetails]);

  return (
   
    <div className='examreports-container'>
      
      <div class="row">
          <div class="col-md-8">
              <div class="page-header-container">
                <h2 class="page-main-header">Attended Exams</h2>
              </div>
          </div>
          <div class="col-md-4"> &nbsp;  </div>
      </div>

      <div class="row">
        <div class="col-sm-12"> <p class="mb-0">&nbsp;</p> </div>
      </div>

      <div className="exam-list exams-list row row-cols-1 row-cols-md-4 g-4">
        {Object.keys(exams).length > 0 && exams.map((exam) => {
          return <div key={exam._id} className="exam-box page-added-item col">
            <div className='added-items-list card h-100'>
            <div class="card-body">
                <h5 class="card-title">{exam?.exam_id?.examName}</h5>
                <p class="card-text mb-2">
                  <strong>Attended Date:</strong> 
                  <br />
                  {new Date(exam.created.date).toLocaleDateString()}
                </p>
              </div>
              <div class="card-footer">
                <button className='reports-view-btn' onClick={() => openModal(exam)}>View Report</button>
              </div>
            </div>
          </div>
        })}
      </div>
      <div className="pagination" style={{display: 'none'}}>
        <button
          onClick={() => setCurrentPage(currentPage - 1)}
          disabled={currentPage === 1}
        >
          Previous
        </button>
        <span>
          Page {currentPage} of {totalPages}
        </span>
        <button
          onClick={() => setCurrentPage(currentPage + 1)}
          disabled={currentPage === totalPages}
        >
          Next
        </button>
      </div>

      <Modal
        isOpen={isModalOpen}
        onRequestClose={closeModal}
        contentLabel="Exam Report"
        style={{
          content: {
            position: 'relative',
            margin: 'auto',
            maxHeight: '90vh',
            width: '82%',
            padding: '20px',
            inset: '25px',
            overflow: 'visible',
          }
        }}
      >
        {examDetails && (
          <div className='examreports-popup-container'>
            <div className='examreports-popup-container-header'>
              <h2><span>Exam Name :</span> {examDetails.examName}</h2>
              <p className='report-close-btn'>
                <button onClick={closeModal}>X</button>
              </p>
            </div>
            <div className='examreports-popup-container-content'>
              <table className='table report-primary-table'>
                <tbody>
                  <tr><th colSpan={4}>Exam Summary</th></tr>
                  <tr>
                    <td className='header-element'>Attended Date</td>
                    <td>{new Date(examDetails.attendedDate).toLocaleDateString()}</td>
                    <td className='header-element'>Scored / Total Marks</td>
                    <td>
                      {examDetails.negative_marks ? (
                        `${examDetails.scoredMarks - examDetails.negative_marks.negative_marks} / ${examDetails.totalMarks}`
                      ) : (
                        `${examDetails.scoredMarks} / ${examDetails.totalMarks}`
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td className='header-element'>Skipped Answers</td>
                    <td>{examDetails.skippedAnswersCount}</td>
                    
                    <td className='header-element'>Time Spent</td>
                    <td>{examDetails.timeSpentTotal} seconds</td>
                  </tr>

                  <tr>
                    <td className='header-element'>Correct Answers</td>
                    <td>{examDetails.correctAnswersCount}</td>
                    <td className='header-element'>Incorrect Answers</td>
                    <td>{examDetails.incorrectAnswersCount}</td>
                  </tr>

                  {examDetails.negative_marks && (
                    <tr><td className='header-element'>Negative Marks</td><td colSpan={3}>{examDetails.negative_marks.negative_marks}</td></tr>
                  )}
                  
                </tbody>
              </table>
              <div className="chart-row">
                <div className="pieChart chart-container">
                  <h3>Correct Vs Incorrect</h3>
                  <div className='charts-info'>
                    {scoreDistributionData && <Pie data={scoreDistributionData}  />}
                  </div>
                  
                </div>
                <div className="chart chart-container">
                  <h3>Complexity Vs No.Of Attempted Questions</h3>
                  <div className='charts-info'>
                    {questionComplexityData && <Bar data={questionComplexityData} options={{ maintainAspectRatio: true }} />}
                  </div>
                </div>
                {sectionWiseMarksData && (
                  <div className="chart chart-container">
                    <h3>Section Wise Scored Marks</h3>
                    <div className='charts-info'>
                    <Bar data={sectionWiseMarksData} options={{ maintainAspectRatio: true, indexAxis: 'y' }} />
                    </div>
                  </div>
                )}
                {timeSpentData && (
                  <div className="chart chart-container">
                    <h3>Time Spent Analysis</h3>
                    <div className='charts-info'>
                    <Bar data={timeSpentData} options={{ maintainAspectRatio: true }} />
                    </div>
                  </div>
                )}
                {learningOutcomesData && (
                  <div className="chart chart-container">
                    <h3>Learning Outcomes Achievement</h3>
                    <div className='charts-info'>
                    <Bar data={learningOutcomesData} options={{ maintainAspectRatio: true, indexAxis: 'y' }} />
                  </div>
                  </div>
                )}
                {subjectWisePerformanceData && (
                  <div className="chart chart-container">
                    <h3>Subject-wise Performance</h3>
                    <div className='charts-info'>
                    <Bar data={subjectWisePerformanceData} options={{ maintainAspectRatio: true }} />
                    </div>
                  </div>
                )}
              </div>
              <h3 className='qanda_details'>'Questions and Answers' Details</h3>
              {/* <h4>Question Details</h4> */}
              <table className='table qanda_details_table'>
                <thead>
                  <tr className='qanda_details_table_header'>
                    <th>Question</th>
                    <th>Marks</th>
                    <th>Time Spent</th>
                    <th>Correct</th>
                  </tr>
                </thead>
                <tbody>
                  {examDetails.questionDetails.map((question, index) => (
                    <tr key={index}>
                      <td><span dangerouslySetInnerHTML={{ __html: question.question }} /></td>
                      <td>{question.marks}</td>
                      <td>{question.timeSpent}</td>
                      <td>{question.isCorrect ? <span class="tickmark">&#10004;</span> : <span class="crossmark">&#10008;</span>}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        )}
      </Modal>
    </div>
   
  );
}
