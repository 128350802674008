
import * as tf from '@tensorflow/tfjs';
import * as cocoSsd from '@tensorflow-models/coco-ssd';
import { FaceMesh } from '@mediapipe/face_mesh';

// Function to load face-api.js models and COCO-SSD model
const loadModels = async () => {
    try {
        await tf.ready();
    } catch (error) {
        console.error('Error loading models:', error);
    }

    let model = '';
    try {
        model = await cocoSsd.load();
    } catch (err) {
        console.log('While calling cocoSsd.load-'+err);
        return '';
    }

    let faceMeshModel = '';
    try {
        faceMeshModel = new FaceMesh({
            locateFile: (file) => `https://cdn.jsdelivr.net/npm/@mediapipe/face_mesh/${file}`,
        });
        await faceMeshModel.initialize();
        await faceMeshModel.setOptions({
            maxNumFaces: 1,
            useCpuInference: true, // Test CPU-based inference
            minDetectionConfidence: 0.3,  // Reduce confidence threshold
            minTrackingConfidence: 0.3,
        });
    } catch (err) {
        console.log('Error while loading facemesh model', err);
        return '';
    }
    

    return { tf, objectDetectionModel: model, faceMeshModel };
};

export default loadModels;
