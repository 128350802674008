import React, { useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import Context from '../../user_details';
import './questions.css';
import SpinnerLoader from '../../helpers/spinner-loader';
import { convertToUTCWithOffsetFormatted, convertToUTCWithOffsetFormattedWithSeconds, getCurrentUTCDateTimeWithSeconds } from '../../helpers/timeHelpers';

export default function ExamApp() {
  const [exams, setExams] = useState([]);
  const [selectedExam, setSelectedExam] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { user } = useContext(Context);

  useEffect(() => {
    const fetchExams = async () => {
      try {
        const groups = await Promise.all(user.groups_assigned.map(group => group._id ));
        const response = await fetch(process.env.REACT_APP_API_URL + `/exam/list/?firm_id=${user.firm_id}&user_id=${user._id}&groups_assigned=${groups}`);
        if (!response.ok) {
          console.log(response);
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        const currentDate = new Date();
        
        // const filteredExams = data.exams.filter(exam => new Date(exam.endDate) > currentDate);
        var filteredExams = await Promise.all(
        data.exams.map(async exam => {
            var currentUTCTime = await getCurrentUTCDateTimeWithSeconds();
            var examEndDateInUTC = await convertToUTCWithOffsetFormattedWithSeconds(exam.endDate, exam.timeZoneOffset);
           return new Date(examEndDateInUTC) > new Date(currentUTCTime) ? exam : null;
          })
        )
        filteredExams = await Promise.all(
          filteredExams.filter(exam => exam)
        )
        setExams(filteredExams);
        setLoading(false);
      } catch (error) {
        setError(error.message);
        setLoading(false);
      }
    };

    fetchExams();
  }, [user.firm_id, user._id, user.groups_assigned]);

  const handleExamClick = exam => {
    setSelectedExam(exam);
    // Navigate to the exam start page or trigger the exam start logic here
    window.location.href = `/start/${exam._id}`;
  };

  const calculateDuration = (start, end) => {
    const startDate = new Date(start);
    const endDate = new Date(end);
    const diffMs = endDate - startDate;

    const diffDays = Math.floor(diffMs / 86400000); // Days
    const diffHrs = Math.floor((diffMs % 86400000) / 3600000); // Hours
    const diffMins = Math.floor((diffMs % 3600000) / 60000); // Minutes

    return `${diffDays > 0 ? `${diffDays} Day(s), ` : ''}${diffHrs} Hour(s) and ${diffMins} Minute(s)`;
  };

  if (loading) {
    // return <div>Loading exams...</div>;
    return <div className="ht80vh w-100 d-flex justify-content-center align-items-center">
              <SpinnerLoader />
          </div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div>
      {selectedExam ? (
        <div>
          <h2>Selected Exam: {selectedExam.examName}</h2>
          <p>Duration: {calculateDuration(selectedExam.startDate, selectedExam.endDate)}</p>
          {/* Additional exam details or start exam button can be added here */}
        </div>
      ) : exams.length > 0 ? (
        <div className='availableExams-list-container p-4'>
          <h2 className='screenHeader'>Available Exams</h2>
          <ul>
            {exams.map(exam => (
              <li key={exam._id}>
                <Link className='exam-name' to={`/start/${exam._id}`} onClick={() => handleExamClick(exam)}>
                  {exam.examName}
                </Link>
                <p className='start-date'><span className='strong'>Start Date: </span>{new Date(exam.startDate).toLocaleString()}</p>
                <p className='end-date'><span className='strong'>End Date: </span>{new Date(exam.endDate).toLocaleString()}</p>
                <p className='duration'><span className='strong'>Duration: </span>{calculateDuration(exam.startDate, exam.endDate)}</p>
                <p className='timezone'><span className='strong'>TimeZone: </span>{exam.timezone}</p>

              </li>
            ))}
          </ul>
        </div>
      ) : (
        <div className='no-exams'>
          <div className='no-exams-text-container'>
            <h2>No Exams Available</h2>
            <p>There are currently no exams available to attend. Please check back later.</p>
          </div>
        </div>
      )}
    </div>
  );
}
