// WebcamErrorMessage.js
import React from 'react';

const WebcamErrorMessage = ({ retryWebcamAccess }) => {
  return (
    <div className="container webcam-error-message mt-5">
      <div className='text-primary'>
        <p>Webcam access is required for this exam.</p>
        <p>Please enable your webcam to continue.</p>
        <p>
            If you don’t want to enable the webcam, you can exit the exam or contact the exam administrator for help.
        </p>
        <p>
            If you blocked access by mistake,{' '}
            <a href="void(0)" onClick={retryWebcamAccess}>
            click here
            </a>{' '}
            to try again.
        </p>
      </div>
      
      <br />
      <h4>How to Enable Webcam Access</h4>
      <div className="text-left">
        <p><strong>1. Refresh the Page:</strong> If you blocked the webcam by mistake, refresh this page and click "Allow" when prompted.</p>
        
        <p><strong>2. Check Browser Settings:</strong></p>
        <ul>
          <li><strong>Google Chrome:</strong> Click the camera icon in the address bar, select "Always allow" for this site, and refresh.</li>
          <li><strong>Firefox:</strong> Click the lock icon, go to "More Information" &gt; "Permissions", and allow camera access.</li>
          <li><strong>Safari:</strong> Go to Safari &gt; Preferences &gt; Websites, and enable camera access for this site.</li>
          <li><strong>Microsoft Edge:</strong> Click the lock icon, enable camera access, and refresh.</li>
        </ul>

        <p><strong>3. Check System Settings:</strong></p>
        <ul>
          <li><strong>Windows:</strong> Go to Settings &gt; Privacy &gt; Camera and ensure apps can access the camera. Restart the browser.</li>
          <li><strong>Mac:</strong> Go to System Preferences &gt Security & Privacy &gt; Camera and allow access for the browser you're using. Restart the browser.</li>
        </ul>

        <p><strong>4. Restart the Browser:</strong> Close and reopen the browser, then return to the exam.</p>

        <p><strong>Still having issues?</strong> Contact your exam administrator for help.</p>
      </div>
    </div>
  );
};

export default WebcamErrorMessage;
